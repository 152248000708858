import React from "react"
import {Link, graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../components/layoutV2"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInnerV2";
import * as PATHS from "../utils/paths";
import {GridColLeftContent, GridColMainContent, GridContainer} from "../components/GridStuff";

const styles = theme => ({
    root: {
        "& p": {
            // color: "#ff0000",
            fontFamily: "itext",
            fontSize: 21,
            lineHeight: 1.48,
            marginBottom: 24,
            hyphens: "manual",
        }
    },
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle} className={classes.root}>
            <SEO title={"About 3iap"} location={{pathname: PATHS.ABOUT_PAGE}}/>

            <LayoutInner>
                <GridContainer style={{flexDirection: "column", alignItems: "center"}}>

                    <GridColMainContent>
                        <h1>About 3iap</h1>

                        <p>3iap (“<i>3 is a pattern</i>”) is an equity-focused information design and research
                            practice, founded by Eli Holder.</p>
                        <p>3iap specializes in psychologically safe, equitable, effective data
                            visualization. Mission-oriented organizations partner with 3iap through design, research,
                            and
                            development projects, as well as through consulting, training, talks, and workshops.</p>

                        <p>
                            Sample client projects include automated report
                            design and design systems for an environmental, social, and governance (ESG) and
                            Environmental Health and Safety (EHS) analytics firm, designing diversity, equity, and
                            inclusion
                            (DEI) reports for a workforce analytics startup, and design consulting for more equitable
                            dashboards to present National Assessment of Educational Progress (NAEP) data about students
                            from marginalized communities, for a major testing and assessment nonprofit.
                        </p>
                        <p>Speaking and training engagements focus on equitable visualizations of social outcomes and
                            health disparities, and have included organizations like the San Francisco Department of Health (SFDPH), the US
                            Census Bureau, NASA, the Data Visualization Society, the AIDS Education and Training Center
                            (Mountain West), and the Australian Evaluation Society.
                        </p>
                        <p>3iap's
                            original research into equity implications of data visualization, in collaboration with
                            investigators
                            at UMass Amherst and Northeastern University, was the first to empirically show how
                            conventional visualizations of social inequality can reinforce harmful stereotypes and
                            misattributions of racial health disparities.</p>

                        <br/><br/>
                        <h2>Who is behind 3iap?</h2>
                        <p>
                            Eli Holder is 3iap's principal designer and researcher.
                            Eli's research and writing focus on the intersection of psychology and visualization design,
                            looking at the not-quite-rational ways that data can reflect and
                            influence people's attitudes, beliefs, emotions, and behaviors
                            (e.g., how visualizing social inequality can reinforce inequality).</p>

                        <p>Eli's work has been published or featured in
                            IEEE VIS,
                            the Data Visualization Society's Nightingale magazine,
                            online publishers like Boing Boing, Data is Plural, and TechCrunch,
                            as well as speaking and workshops for
                            the San Francisco Department of Public Health,
                            the US Census Bureau,
                            and DVS Outlier.
                            Engagement with the visualization design community includes mentoring upcoming
                            designers, regular contributions to the Data Visualization Society's Nightingale blog,
                            various podcasts such as PolicyViz and WeAllCount, as well as Effect Affect, a newsletter
                            covering the science and social impact of visualization design.</p>

                        <p>
                            Before 3iap, Eli was a startup founder whose first company, Unblab, was acquired by AOL in 2010,
                            and a product leader at companies like PwC, Noom, Chartbeat, and StreetLinx (acquired in 2022).
                            Eli graduated with a B.S. in computer science from UNC Chapel Hill, where he studied board game
                            AI and built 3D-visualizations for microscopes.
                        </p>
                        <p>
                            You can follow Eli on <a href={"https://bsky.app/profile/elibryan.bsky.social"} target={"_blank"}>Bluesky</a>, <a href={"https://medium.com/@elibryan"} target={"_blank"}>Medium</a>, and <a href={"https://www.linkedin.com/in/eliholder/"} target={"_blank"}>LinkedIn</a>.
                        </p>

                    </GridColMainContent>
                </GridContainer>


            </LayoutInner>

        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
